<template>
    <b-card>
        <b-tabs>
            <b-tab title="Primary Details" active>
                <b-card-text>
                    <b-form-group label="Dispatch No." label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right" description="Serialized Dispatch ID per company">
                        <span class="numFont">{{ row.item.dispatchNo }}</span>
                    </b-form-group>
                    <b-form-group label="Dispatch ID" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right"  description="Dispatch Unique ID">
                        <span class="numFont">{{ row.item.dispatchId }}</span>
                    </b-form-group>

                    <b-form-group label="Source:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em> {{ sourceCompany }}
                        </em>
                    </b-form-group>

                    <b-form-group label="Destination:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <em>
                            {{ destinationCompany }}
                        </em>
                        &nbsp;&nbsp;
                    </b-form-group>

                    <b-form-group label="Location:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <strong>
                            <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
                                @click.stop="showDispatchLocation(row.item)" variant="dark" class="mr-1">
                                <em class="fa fa-map-marker fa-lg"></em>
                            </b-button>
                        </strong>
                        <span class="numFont">
                            {{ getCoordinates(row.item.geoaddress) }}
                        </span>
                    </b-form-group>

                    <b-form-group label="Driver:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.driver.name }}
                    </b-form-group>

                    <b-form-group label="Plate No./Conduction No.:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ row.item.transportation.plateNo }}
                        </span>
                    </b-form-group>

                    <b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ showFormattedDate(row.item.dateCreated) }}
                    </b-form-group>
                </b-card-text>
            </b-tab>
        </b-tabs>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary"
                    v-b-tooltip.hover.top="'Hide Details'">
                    <em class="icon-arrow-up"></em>
                </b-button>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'dispatch-alert-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        },
    },

    computed: {
        sourceCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
        },
        destinationCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
        },
    },

    methods: {

        getCoordinates(geoaddress) {
            return LocationUtil.getCoordinates(geoaddress);
        },

        showFormattedDate(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },

        getDisplayValue(value) {
            if (!value || value.length === 0) {
                return '-';
            }
            return value;
        },

        showDispatchLocation(details) {
            const params = {
                source: details.source,
                destination: details.destination,
                courier: {
                    driver: details.driver.name,
                    plateNo: details.transportation.plateNo,
                    geoaddress: details.geoaddress
                },
            };

            EventBus.$emit('onUpdateDispatchLocationView', params);
            this.$bvModal.show('dispatch-alert-report-location-view');
        },
    },
};
</script>